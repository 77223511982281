import React from "react"

import SEO from "../components/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import safeGetImage from "../utils/safeGetImage"
import HeroSinglePage from "../components/HeroSinglePage"
import { graphql } from 'gatsby';

export default function Single({
  data, // this prop will be injected by the GraphQL query
}) {
    let { title, text, backgroundImage } = data.mdx.frontmatter;
    return (
        <>
            <SEO title="Privacy Policy" />
            <HeroSinglePage
                className={"page__hero"}
                title={title}
                text={text}
                backgroundImage={`url(${safeGetImage(backgroundImage)})`}
            />

            <div className="blog-post-container container">
                <div className="blog-post">
                    <div className="blog-post-content">
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </div>
                </div>
            </div>
        </>
    )
}

export const pageQuery = graphql`
  query PageByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        title
        path
        text
        backgroundImage
      }
    }
  }
`
