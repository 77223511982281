import React from "react"
import PropTypes from "prop-types"

const Panel = ({
  color,
  className,
  children,
  title,
  subtitle,
  body,
  backgroundImage,
}) => {
  return (
    <section
      id={className}
      className={`container homepage-panel--${color} ${className}`}
    >
      <div className="homepage-panel__backing" style={{ backgroundImage }} />

      {!title && !subtitle && !body ? null : (
        <header className="homepage-panel__header">
          {title && <h1 className="homepage-panel__title">{title}</h1>}

          {subtitle && <h2 className="homepage-panel__subtitle">{subtitle}</h2>}

          {body}
        </header>
      )}

      {children}
    </section>
  )
}

Panel.defaultProps = {
  color: "white",
  className: "",
  title: "",
  text: "",
  subtitle: "",
}

Panel.propTypes = {
  color: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.node,
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
}

export default Panel
